<template>
    <k-layout>
        <div v-show="audience === 'PRIVATE'" class="tw-bg-purple-alt tw-px-4" role="alert">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl tw-py-3">
                <div class="tw-flex tw-w-full xl:tw-space-x-8">
                    <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                        <!-- empty -->
                    </div>
                    <div class="tw-w-full tw-flex tw-items-center tw-text-purple">
                        <k-icon name="info" class="tw-flex-shrink-0" />
                        <p class="tw-ml-2 tw-text-sm">
                            {{ $t('profile.descriptions.private_resume') }}
                            <a @click="goToSettingsPrivacy" class="tw-underline">{{ $t('profile.descriptions.private_resume_change') }}</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="audience === 'TOKEN' && !cvSettings.token" class="tw-bg-purple-alt tw-px-4" role="alert">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl tw-py-3">
                <div class="tw-flex tw-w-full xl:tw-space-x-8">
                    <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                        <!-- empty -->
                    </div>
                    <div class="tw-w-full tw-flex tw-items-center tw-text-purple">
                        <k-icon name="info" class="tw-flex-shrink-0" />
                        <p class="tw-ml-2 tw-text-sm">
                            {{ $t('profile.descriptions.token_resume_no_password') }}
                            <a @click="goToSettingsPrivacy" class="tw-underline">{{ $t('profile.descriptions.private_resume_change') }}</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <header class="tw-bg-white tw-pt-12 tw-px-4">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
                <div class="tw-flex tw-w-full xl:tw-space-x-8">
                    <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                        <!-- empty -->
                    </div>
                    <div class="tw-w-full">
                        <h1 class="tw-heading-1">{{ $t('global.my_resume') }}</h1>
                    </div>
                </div>
            </div>

            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl tw-mt-8">
                <div class="tw-flex tw-w-full xl:tw-space-x-8">
                    <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                        <!-- empty -->
                    </div>
                    <div class="tw-w-full">
                        <v-tabs show-arrows icons-and-text v-model="tab" background-color="transparent" class="tw--ml-3" height="56">
                            <v-tabs-slider class="tab--active"></v-tabs-slider>
                            <v-tab
                                :ripple="false"
                                class="tw-font-bold tw-tracking-normal tw-normal-case tw-text-gray-500 hover:tw-text-gray-800 tw-transition-colors tw-duration-200 tw-min-w-0 tw-mr-4"
                                active-class="tab--active"
                            >
                                <span class="tw-inline-block">
                                    {{ $t('profile.data_in_cv') }}
                                </span>
                            </v-tab>
                            <v-tab
                                :ripple="false"
                                class="tw-font-bold tw-tracking-normal tw-normal-case tw-text-gray-500 hover:tw-text-gray-800 tw-transition-colors tw-duration-200 tw-min-w-0"
                                active-class="tab--active"
                            >
                                <span class="tw-inline-block">
                                    {{ $t('navigation.settings') }}
                                </span>
                            </v-tab>
                        </v-tabs>
                    </div>
                </div>
            </div>
        </header>

        <!-- <main v-if="user && user.emailVerified === true" class="tw-mt-8 tw-px-4 tw-mb-24"> -->
        <main v-if="isUserSignedIn" class="tw-mt-8 tw-px-4 tw-mb-24">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
                <div v-show="tab === 0">
                    <ka-user-cv-tab-edit ref="edit" />
                </div>
                <div v-show="tab === 1">
                    <ka-user-cv-tab-settings ref="settings" />
                </div>
            </div>
        </main>

        <!-- <main v-else class="tw-mt-8 tw-px-4 tw-mb-24">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
                <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
                    <div class="tw-w-full">
                        <section class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-p-8">
                            <ka-user-verification-tab />
                        </section>
                    </div>
                </div>
            </div>
        </main> -->

        <!-- <aside class="bottom-right-section" :class="hasCookieBar ? 'bottom-right-section--cookie' : ''">
            <div class="illustrative-section">
                <p class="illustrative-section__description">Pozrite si, ako môže<br />vyzerať aj Váš životopis.</p>
                <k-button href="https://www.koderia.sk/cv/johndoe" target="_blank" color="green" class="mt-4">
                    <k-icon size="20" name="cv" class="mr-2" />NÁZORNÝ ŽIVOTOPIS
                </k-button>
            </div>

            <ka-user-cv-wage :wage="realtimeWage || wage" class="mt-4" />
        </aside> -->
    </k-layout>
</template>

<script>
// import firebase from "firebase/app";
import "firebase/firestore";

import KaUserCvTabEdit from "./ka-user-cv-tab-edit";
import KaUserCvTabSettings from "./ka-user-cv-tab-settings";
import {mapGetters} from "vuex";
// import KaUserVerificationTab from "./ka-user-verification-tab";

export default {
    components: {
        KaUserCvTabEdit,
        KaUserCvTabSettings
        // KaUserVerificationTab
    },
    created() {
        const { tab } = this.$route.query || {};

        if (tab === "nastavenia") {
            this.tab = 1;
        }

        this.$root.$on("hide-cookie-bar", () => {
            this.hasCookieBar = false;
        });
    },
    data() {
        return {
            hasCookieBar: !window.localStorage.showCookiesBar,
            tab: null,
            preserveEditForm: false
        };
    },
    computed: {
        ...mapGetters({
            isUserSignedIn: 'AUTH/getIsUserSignedIn',
            cvSettings: 'USER/getCvSettings'
        }),
        audience() {
            const { audience } = this.cvSettings || {};

            return audience?.value || null;
        }
    },
    watch: {
        tab(currentTab, previousTab) {
            let query = Object.assign({}, this.$route.query);
            delete query.tab;
            this.$router.replace({ query });

            // Previous tab was edit
            if (previousTab === 0 && currentTab !== previousTab) {
                this.preserveEditForm = true;
            } else {
                this.preserveEditForm = false;
            }
        },
    },
    methods: {
        goToSettingsPrivacy() {
            this.tab = 1;
            this.$nextTick(() => {
                this.$vuetify.goTo("#Súkromie", { offset: 100 });
            });
        },
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-tabs-bar {
    margin: 0 0.75rem;
    z-index: 1;
}

.tab {
    font-family: "soleil", sans-serif !important;
    text-transform: none;
    font-size: map-get(map-get($text, title), size);
    letter-spacing: 0em;
    font-weight: map-get(map-get($text, title), font-weight);
}

.tab--active {
    color: $koderia-black !important;
}

.tabs-slider {
    color: $koderia-black !important;
}

.bottom-right-section {
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;

    @include -lg {
        display: none;
    }
}

.bottom-right-section--cookie {
    bottom: 8.438rem;
}

.illustrative-section {
    text-align: right;
}

.illustrative-section__description {
    font-size: 0.875rem;
    text-align: right;
}

.illustrative-section--cookie {
    bottom: 8.438rem;
}
</style>
