<template>
    <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
        <ka-user-cv-linkedin-modal @response="fillLinkedinImportResponse" :value="linkedinDialog" @close="linkedinDialog = false" />

        <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-8">
            <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                <div v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
                    <div class="tw-flex tw-flex-col tw-space-y-4 tw-text-right">
                        <a
                            v-for="link in links"
                            :key="link.href"
                            @click="$vuetify.goTo(link.href, { offset: link.offset })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t(link.label) }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="tw-flex-grow">
                <div class="tw-flex tw-flex-col">
                    <section class="tw-bg-white tw-rounded-2xl tw-p-4 lg:tw-p-10">
                        <ka-cv-form-section-profile v-model="form.general" :invalid-fields="invalidFields" />
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="employments"
                            :title="$t('global.employment')"
                            :sub-title="$t('profile.descriptions.employment')"
                            :addTitle="form.employments.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                            @add="addSectionItem('employments')"
                        >
                            <template #icon>
                                <k-icon name="laptop" :size="20" />
                            </template>

                            <div v-for="(employment, idx) in form.employments" :key="employment.id">
                                <ka-cv-form-section-item-employment
                                    v-model="form.employments[idx]"
                                    :sub-title="`${$t('global.employment')} ${idx + 1}`"
                                    @remove="removeSectionItem('employments', idx)"
                                    @move-up="moveUpSectionItem('employments', idx)"
                                    @move-down="moveDownSectionItem('employments', idx)"
                                    @expertises-change="addEmploymentExpertises"
                                    :move-up-disabled="idx === 0"
                                    :move-down-disabled="idx === form.employments.length - 1"
                                    :bottom-line="idx < form.employments.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="stack"
                            :title="$t('profile.stack')"
                            :sub-title="$t('profile.descriptions.stack')"
                            simple
                        >
                            <template #icon>
                                <k-icon name="layer_group" :size="20" />
                            </template>

                            <template #divider>
                                <p class="tw-bg-blue-light tw-text-blue tw-text-sm tw-leading-relaxed tw-py-1 tw-px-3 tw-my-6 tw-rounded-md">
                                    {{ $t('profile.descriptions.stack_notify') }}
                                </p>
                            </template>

                            <ka-cv-form-section-item-experience v-model="form.expertises" :selected-expertises-ids="selectedExpertisesIds" />
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="education"
                            :title="$t('profile.education')"
                            :sub-title="$t('profile.descriptions.education')"
                            :addTitle="$t(form.educations.length ? 'global.actions.add_more' : 'global.actions.add')"
                            @add="addSectionItem('educations')"
                        >
                            <template #icon>
                                <k-icon name="user_graduate" :size="20" />
                            </template>

                            <div v-for="(education, idx) in form.educations" :key="education.id">
                                <ka-cv-form-section-item-education
                                    v-model="form.educations[idx]"
                                    :sub-title="`${$t('profile.school')} ${idx + 1}`"
                                    @remove="removeSectionItem('educations', idx)"
                                    @move-up="moveUpSectionItem('educations', idx)"
                                    @move-down="moveDownSectionItem('educations', idx)"
                                    :move-up-disabled="idx === 0"
                                    :move-down-disabled="idx === form.educations.length - 1"
                                    :bottom-line="idx < form.educations.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="references"
                            :title="$t('profile.references')"
                            :sub-title="$t('profile.descriptions.references')"
                            :addTitle="form.references.length ? $t('global.actions.add_more') : $t('global.actions.add')"
                            @add="addSectionItem('references')"
                        >
                            <template #icon>
                                <k-icon name="rocket" :size="16" />
                            </template>

                            <div v-for="(reference, idx) in form.references" :key="reference.id">
                                <ka-cv-form-section-item-reference
                                    v-model="form.references[idx]"
                                    :sub-title="`${$t('profile.reference')} ${idx + 1}`"
                                    @remove="removeSectionItem('references', idx)"
                                    @move-up="moveUpSectionItem('references', idx)"
                                    @move-down="moveDownSectionItem('references', idx)"
                                    :move-up-disabled="idx === 0"
                                    :move-down-disabled="idx === form.references.length - 1"
                                    :bottom-line="idx < form.references.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="certificates"
                            :title="$t('profile.certificates')"
                            :sub-title="$t('profile.descriptions.certificates')"
                            :addTitle="form.certificates.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                            @add="addSectionItem('certificates')"
                        >
                            <template #icon>
                                <k-icon name="certificate" :size="20" />
                            </template>

                            <div v-for="(certificate, idx) in form.certificates" :key="certificate.id">
                                <ka-cv-form-section-item-certificate
                                    v-model="form.certificates[idx]"
                                    :sub-title="`${$t('profile.certificate')} ${idx + 1}`"
                                    @remove="removeSectionItem('certificates', idx)"
                                    @move-up="moveUpSectionItem('certificates', idx)"
                                    @move-down="moveDownSectionItem('certificates', idx)"
                                    :move-up-disabled="idx === 0"
                                    :move-down-disabled="idx === form.certificates.length - 1"
                                    :bottom-line="idx < form.certificates.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="languages"
                            :title="$t('profile.languages')"
                            :sub-title="$t('profile.descriptions.languages')"
                            :addTitle="form.languages.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                            @add="addSectionItem('languages')"
                        >
                            <template #icon>
                                <k-icon name="language" :size="20" />
                            </template>

                            <div class="tw-flex tw-flex-col tw-gap-6">
                                <ka-cv-form-section-item-language
                                    v-for="(language, idx) in form.languages"
                                    :key="language.id"
                                    v-model="form.languages[idx]"
                                    @remove="removeSectionItem('languages', idx)"
                                    @move-up="moveUpSectionItem('languages', idx)"
                                    @move-down="moveDownSectionItem('languages', idx)"
                                    :move-up-disabled="idx === 0"
                                    :move-down-disabled="idx === form.languages.length - 1"
                                    :show-input-labels="idx === 0"
                                    :no-border="true"
                                    :dense="true"
                                    :selected-languages-ids="selectedLanguagesIds"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>
                </div>
            </div>

            <div class="tw-w-full tw-mt-10 lg:tw-mt-0 lg:tw-w-84 tw-flex-shrink-0">
                <aside v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
                    <submit-panel
                        :loading="profileStatus.isLoading"
                        :is-generating-cv="pdfStatus.isLoading"
                        :success="profileStatus.isSuccess"
                        @submit="submit"
                        @save-pdf="generatePdf"
                        @linkedin-import="importFromLinkedin"
                    />

<!--                    TODO: Pridat neskor-->
<!--                    <cv-job-offers :experience="form.experience" :profession="form.general.profession" />-->
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import Sticky from "vue-sticky";

import SubmitPanel from "./components/cv-submit-panel.vue";

import KaCvFormSection from "./cv-sections/ka-cv-form-section";
import KaUserCvLinkedinModal from "./ka-user-cv-linkedin-modal";
import KaCvFormSectionProfile from "./cv-sections/ka-cv-form-section-profile";
import KaCvFormSectionItemLanguage from "./cv-sections/ka-cv-form-section-item-language";
import KaCvFormSectionItemReference from "./cv-sections/ka-cv-form-section-item-reference";
import KaCvFormSectionItemEducation from "./cv-sections/ka-cv-form-section-item-education";
import KaCvFormSectionItemEmployment from "./cv-sections/ka-cv-form-section-item-employment";
import KaCvFormSectionItemExperience from "./cv-sections/ka-cv-form-section-item-experience";
import KaCvFormSectionItemCertificate from "./cv-sections/ka-cv-form-section-item-certificate";
// import CvJobOffers from "@web/user/components/cv-job-offers.vue";
import {mapGetters} from "vuex";
import {cloneDeep, random} from "lodash";

export default {
    directives: {
        sticky: Sticky
    },
    components: {
        // CvJobOffers,
        SubmitPanel,
        KaCvFormSection,
        KaUserCvLinkedinModal,
        KaCvFormSectionProfile,
        KaCvFormSectionItemLanguage,
        KaCvFormSectionItemReference,
        KaCvFormSectionItemEducation,
        KaCvFormSectionItemEmployment,
        KaCvFormSectionItemExperience,
        KaCvFormSectionItemCertificate
    },
    props: {
        preserveForm: {
            type: Boolean,
            default: false
        },
        pdfUrl: {
            type: String
        }
    },
    created() {
        this.$store.dispatch('ENUMS/fetchLanguages')
        this.fillFormData();
    },
    data() {
        return {
            linkedInImportUsed: false,
            linkedinDialog: false,
            form: {
                general: {},
                employments: [],
                expertises: [],
                educations: [],
                references: [],
                certificates: [],
                languages: []
            },
            invalidFields: {
                firstName: false,
                lastName: false,
                profession: false
            }
        };
    },
    computed: {
        ...mapGetters('USER', {
            profile: 'getUserProfile',
        }),
        profileStatus() {
            return this.$store.getters['USER/getStatus']('updateProfileStatus')
        },
        pdfStatus() {
            return this.$store.getters['USER/getStatus']('generatePdfStatus')
        },
        links() {
            return [
                {
                    href: '#basic-info',
                    offset: 145,
                    label: 'profile.basic_information'
                },
                {
                    href: '#employments',
                    offset: 140,
                    label: 'global.employment'
                },
                {
                    href: '#stack',
                    offset: 140,
                    label: 'profile.stack'
                },
                {
                    href: '#education',
                    offset: 140,
                    label: 'profile.education'
                },
                {
                    href: '#references',
                    offset: 140,
                    label: 'profile.references'
                },
                {
                    href: '#certificates',
                    offset: 140,
                    label: 'profile.certificates'
                },
                {
                    href: '#languages',
                    offset: 140,
                    label: 'profile.languages'
                }
            ]
        },
        selectedExpertisesIds() {
            return this.form.expertises.filter(({ expertiseId }) => expertiseId).map(({ expertiseId }) => expertiseId)
        },
        selectedLanguagesIds() {
            return this.form.languages.filter(({ language }) => language?.id).map(({ language }) => language.id)
        }
    },
    watch: {
        "profile": {
            immediate: true,
            handler() {
                this.fillFormData();
            }
        },
    },
    methods: {
        addEmploymentExpertises(employmentExpertises) {
            const copy = cloneDeep(this.form.expertises);

            (employmentExpertises || []).forEach(expertise => {
                if ((this.selectedExpertisesIds).indexOf(expertise.expertiseId) === -1) {
                    if (copy[copy.length - 1].expertiseId === null) {
                        copy[copy.length - 1] = expertise
                    } else {
                        copy.push(expertise)
                    }
                }
            })

            this.form.expertises = cloneDeep(copy)
        },
        generatePdf() {
            this.$store.dispatch('USER/generatePdf')
        },
        importFromLinkedin() {
            this.linkedinDialog = true;
        },
        fillFormData() {
            if (this.preserveForm === true || !this.profile) {
                return;
            }

            const form = {};

            const {
                expertises,
                employments,
                educations,
                references,
                certificates,
                languages,
                ...general
            } = this.profile;

            form["general"] = general;
            form["expertises"] = expertises;
            form["employments"] = employments;
            form["educations"] = educations;
            form["certificates"] = certificates;
            form["references"] = references;
            form["languages"] = languages;

            this.form = cloneDeep(form);
        },
        transformFormSection(id, data) {
            if (id && typeof id === 'number') {
                return data
            }

            return {
                id,
                ...data
            }
        },
        scrollToFirstFieldError() {
            const el = document.querySelector('.k-input--invalid')

            if (el) {
                window.scrollTo({
                    top: el.getBoundingClientRect().top + window.pageYOffset - 150,
                    behavior: 'smooth'
                })
            }
        },
        submit() {
            this.invalidFields.firstName = this.form.general.firstName.trim().length === 0
            this.invalidFields.lastName = this.form.general.lastName.trim().length === 0
            this.invalidFields.profession = this.form.general.profession === null

            if (this.invalidFields.firstName || this.invalidFields.lastName || this.invalidFields.profession) {
                setTimeout(() => this.scrollToFirstFieldError(), 100)

                return
            }

            const expertises = this.form.expertises.length > 0 ? this.form.expertises.slice(0, -1).map(({ id, ...rest }) => this.transformFormSection(id, rest)) : []
            const employments = this.form.employments.map(({ id, ...rest }) => {
                const employment = this.transformFormSection(id, rest)
                employment.expertises = employment.expertises.map(({ expertiseId }) => ({ expertiseId }))

                return employment
            })
            const educations = this.form.educations.map(({ id, ...rest }) => this.transformFormSection(id, rest))
            const references = this.form.references.map(({ id, ...rest }) => this.transformFormSection(id, rest))
            const certificates = this.form.certificates.map(({ id, ...rest }) => this.transformFormSection(id, rest))
            const languages = this.form.languages.map(({ id, ...rest }) => this.transformFormSection(id, rest))

            const detail = {
                ...this.form.general,
                gdprAgreementAccepted: true,
                expertises,
                employments,
                educations,
                references,
                certificates,
                languages
            }

            this.$store.dispatch('USER/updateProfile', detail)
        },
        addSectionItem(sectionName, itemData = { id: random(0, Number.MAX_SAFE_INTEGER) }) {
            this.form[sectionName].push(itemData)
        },
        removeSectionItem(sectionName, idx) {
            this.form[sectionName].splice(idx, 1)
        },
        moveUpSectionItem(sectionName, idx) {
            const arr = this.form[sectionName];
            this.form[sectionName] = this.arrayMove(arr, idx, idx > 1 ? idx - 1 : 0);
        },
        moveDownSectionItem(sectionName, idx) {
            const arr = this.form[sectionName];
            this.form[sectionName] = this.arrayMove(arr, idx, idx < arr.length - 1 ? idx + 1 : arr.length - 1);
        },
        arrayMove(sourceArr, old_index, new_index) {
            const arr = [...sourceArr];

            if (new_index >= arr.length) {
                let k = new_index - arr.length + 1;

                while (k--) {
                    arr.push(undefined);
                }
            }

            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return cloneDeep(arr);
        },
        fillLinkedinImportResponse(response) {
            this.linkedInImportUsed = true;

            (response || []).forEach(section => {
                // try {
                //     // Experience
                //     if (section.name === "skills") {
                //         (section.content || []).forEach(item => {
                //             this.addExperience(item["Name"]);
                //         });
                //     }
                // } catch (e) {
                //     console.error(e);
                // }

                try {
                    // Certificate
                    if (section.name === "courses") {
                        (section.content || []).forEach(item => {
                            this.addSectionItem("certificate", {
                                name: item["Name"]
                            });
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Education
                    if (section.name === "education") {
                        (section.content || []).forEach(item => {
                            this.addSectionItem("education", {
                                grade: item["Degree Name"],
                                school: item["School Name"],
                                city: "",
                                country: "",
                                from: +item["Start Date"],
                                to: +item["End Date"] || "dnes",
                                description: item["Notes"]
                            });
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Employment
                    if (section.name === "positions") {
                        const year = val => {
                            const y = (val || "").split(" ")[1];
                            return +y;
                        };

                        const month = val => {
                            const mMap = {
                                Jan: "január",
                                Feb: "február",
                                Mar: "marec",
                                Apr: "apríl",
                                May: "máj",
                                Jun: "jún",
                                Jul: "júl",
                                Aug: "august",
                                Sep: "september",
                                Oct: "október",
                                Nov: "november",
                                Dec: "december"
                            };
                            const m = (val || "").split(" ")[0];
                            return mMap[m];
                        };

                        (section.content || []).forEach(item => {
                            const employment = {
                                position: item["Title"],
                                company: item["Company Name"],
                                toMonth: month(item["Finished On"]) || "dnes",
                                toYear: year(item["Finished On"]),
                                fromMonth: month(item["Started On"]),
                                fromYear: year(item["Started On"]),
                                description: item["Description"]
                            };
                            this.addSectionItem("employment", employment);
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Language
                    if (section.name === "languages") {
                        const levelsMap = {
                            "native or bilingual proficiency": "native-speaker",
                            "full professional proficiency": "expert",
                            "professional working proficiency": "advanced",
                            "limited working proficiency": "intermediate",
                            "elementary proficiency": "beginner"
                        };

                        (section.content || []).forEach(item => {
                            this.addSectionItem("language", {
                                language: item["Name"],
                                level: levelsMap[String(item["Proficiency"]).toLowerCase()] || "beginner"
                            });
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Profile
                    if (section.name === "profile") {
                        this.form.general.firstName = this.form.general.firstName || section.content[0]["First Name"];
                        this.form.general.lastName = this.form.general.lastName || section.content[0]["Last Name"];
                        this.form.general.bio = this.form.general.bio || section.content[0]["Summary"];
                    }
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.mt-13 {
    margin-top: 3.25rem;
}

.edit-in-profile-reminder {
    margin-top: -1rem;
}
</style>
