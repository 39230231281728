import { render, staticRenderFns } from "./ka-user-cv-tab-edit.vue?vue&type=template&id=e7bf6d2e&scoped=true&"
import script from "./ka-user-cv-tab-edit.vue?vue&type=script&lang=js&"
export * from "./ka-user-cv-tab-edit.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-cv-tab-edit.vue?vue&type=style&index=0&id=e7bf6d2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7bf6d2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KIcon from '@web/components/core/k-icon'
installComponents(component, {KIcon})
