<template>
    <div>
        <section-header>
            <template #icon>
                <k-icon name="user" :size="16" />
            </template>

            <template #title>
                <h3 id="basic-info" class="tw-heading-3">{{ $t('profile.basic_information') }}</h3>
            </template>

            <template #subtitle>
                {{ $t('profile.descriptions.basic_information') }}
            </template>
        </section-header>

        <div class="tw-mt-6 tw-pt-6 tw-border-t-2 tw-border-gray-100">
<!--            <div class="tw-flex tw-items-center tw-gap-x-4 tw-mb-4">-->
<!--                <ka-user-avatar :size="60" />-->

<!--                <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row">-->
<!--                    <k-button color="secondary" custom-class="tw-py-1 tw-px-4 tw-leading-relaxed">-->
<!--                        {{ $t('profile.actions.change_photo') }}-->
<!--                    </k-button>-->

<!--                    <k-button-->
<!--                        v-show="profile && profile.avatar"-->
<!--                        color="text"-->
<!--                        custom-class="tw-py-1 tw-px-0 hover:tw-text-red hover:tw-bg-white tw-leading-relaxed"-->
<!--                    >-->
<!--                        {{ $t('profile.actions.delete_photo') }}-->
<!--                    </k-button>-->
<!--                </div>-->
<!--            </div>-->

            <v-row dense>
                <v-col cols="12" sm="3">
                    <k-input v-model="value.firstName" :title="$t('global.inputs.first_name')" required :invalid="invalidFields.firstName" />
                </v-col>
                <v-col cols="12" sm="3">
                    <k-input v-model="value.lastName" :title="$t('global.inputs.last_name')" required :invalid="invalidFields.lastName" />
                </v-col>
                <v-col cols="12" sm="6">
                    <k-combobox v-model="value.profession" :items="allProfessions" :title="$t('global.inputs.profession')" required item-value="value" item-text="displayString" :invalid="invalidFields.profession" />
                </v-col>
            </v-row>

            <v-row dense class="tw-mt-2">
                <v-col cols="12" sm="4">
                    <k-input v-model="value.email" :title="$t('global.inputs.email')" type="email" />
                </v-col>

                <v-col cols="12" sm="4">
                    <k-input v-model="value.phone" :title="$t('global.inputs.phone')" />
                </v-col>

                <v-col cols="12" sm="4">
                    <k-input v-model="value.web" :title="$t('profile.inputs.web_social_network')" />
                </v-col>
            </v-row>

            <v-row dense class="tw-mt-2">
                <v-col>
                    <k-textarea :title="$t('profile.inputs.about_me')" :sub-title="$t('profile.descriptions.about_me_input')" v-model="value.bio" rows="5" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionHeader from '@web/user/components/section-header'
// import KaUserAvatar from '@web/components/ka-user-avatar'

export default {
    components: {
        // KaUserAvatar,
        SectionHeader,
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        invalidFields: {
            type: Object,
            default: () => ({
                firstName: false,
                lastName: false,
                profession: false
            })
        }
    },
    computed: {
        ...mapGetters("ENUMS", {
            allProfessions: 'getProfessions',
        }),
        ...mapGetters('USER', {
            profile: 'getUserProfile'
        })
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.mt-15 {
    margin-top: 3.75rem;
}

.mt-13 {
    margin-top: 3.25rem;
}

.kod-hr {
    margin-top: 1.063rem !important;
}

.delete-account-link {
    text-decoration: underline;
    color: $koderia-grey;
}

.delete-account-link:hover {
    color: $koderia-red;
}

.form-error-message p {
    margin-top: 0 !important;
}

.dialog {
    padding: 4rem;
    border-radius: 0.125rem !important;
}
</style>
